import { ReactNode } from 'react';

import { Lead, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { Shadow, useTheme } from '@oui/app-core/src/styles';

export const RecordProgressCard = ({
  testID,
  children,
  datePicker,
  title,
  description,
}: {
  testID?: string;
  children: ReactNode;
  datePicker: ReactNode;
  title: string;
  description: string;
}) => {
  const { theme } = useTheme();
  return (
    <View
      data-testid={testID}
      style={[
        Shadow.medium,
        {
          borderRadius: 20,
          overflow: 'hidden',
          backgroundColor: 'white',
        },
      ]}
    >
      <View
        style={{
          paddingHorizontal: 15,
          paddingVertical: 12,
          backgroundColor: theme.color.accentTwo300,
        }}
      >
        {datePicker}
      </View>

      <View
        style={{
          paddingTop: 25,
          paddingBottom: 21,
          paddingHorizontal: 15,
          alignItems: 'center',
          gap: 25,
        }}
      >
        <View
          style={{
            gap: 7,
            alignItems: 'center',
          }}
        >
          <Lead text={title} />
          <Text textAlign="center" text={description} />
        </View>
        {children}
      </View>
    </View>
  );
};
