import { useApolloClient, useQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { roundToNearestMinutes } from 'date-fns';
import { useCallback, useContext, useLayoutEffect, useMemo } from 'react';
import * as z from 'zod';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Button } from '@oui/app-core/src/components/Button';
import { HeaderButtons, HeaderItem } from '@oui/app-core/src/components/HeaderButtons';
import { getImageInputAssetDetails } from '@oui/app-core/src/components/ImageInput';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { UnsavedChangesModal } from '@oui/app-core/src/components/UnsavedChangesModal';
import { View } from '@oui/app-core/src/components/View';
import {
  Controller,
  DateFormInput,
  FormContainer,
  ImageFormInput,
  SegmentedControlFormInput,
  TextFormInput,
  useZodForm,
} from '@oui/app-core/src/form';
import { AccessibleInput } from '@oui/app-core/src/hooks/useAccessibleInput';
import { useCurrentUser } from '@oui/app-core/src/hooks/useCurrentUser';
import {
  ResumableUploadManagerContext,
  SessionUri,
} from '@oui/app-core/src/lib/resumableUploadManager';
import { useTheme } from '@oui/app-core/src/styles';
import { getEatingLogMealTypeContent } from '@oui/lib/src/eatingLog';
import {
  formatGQLDate,
  formatGQLTime,
  parseGQLDateAndTime,
  parseGQLDateTime,
} from '@oui/lib/src/gqlDate';
import { graphql } from '@oui/lib/src/graphql/tada';
import {
  EatingLogEntryInput,
  EatingLogMealType,
  EatingLogMealTypeSchema,
} from '@oui/lib/src/types';
import { GQLDateTime } from '@oui/lib/src/types/scalars';

import {
  EatingLogCalendarQueryName,
  EatingLogEntriesListQueryName,
  EatingLogEntryFragment,
} from '../components';
import { StackScreenProps } from '../types/navigation';

const Schema = z.object({
  date: z.string(),
  time: z.string(),
  binged: z.boolean({ required_error: 'Must be selected' }),
  description: z.string().nullish(),
  meal: EatingLogMealTypeSchema.optional(),
  image: z.string().nullish(),
});

export const EditEatingLogEntryQuery = graphql(`
  query EditEatingLogEntry($practiceID: UUID!) {
    user {
      ID
      role {
        ID
        eatingLogEntryByID(practiceID: $practiceID) {
          practiceID
          imageUrl
          eatingLogEntry {
            binged
            description
            meal
            timestamp
          }
        }
      }
    }
  }
`);

export const EditEatingLogEntryCreateMutation = graphql(
  `
    mutation EditEatingLogEntryCreateMutation(
      $input: AddEatingLogEntryInput!
      $imageContentType: String!
    ) {
      eatingLogEntry: addEatingLogEntry(input: $input) {
        practiceID
        imageUploadUrl(input: { contentType: $imageContentType })
        ...EatingLogEntry
      }
    }
  `,
  [EatingLogEntryFragment],
);

export const EditEatingLogEntryUpdateMutation = graphql(
  `
    mutation EditEatingLogEntryUpdateMutation(
      $input: UpdateEatingLogEntryInput!
      $imageContentType: String!
    ) {
      eatingLogEntry: updateEatingLogEntry(input: $input) {
        practiceID
        imageUploadUrl(input: { contentType: $imageContentType })
        ...EatingLogEntry
      }
    }
  `,
  [EatingLogEntryFragment],
);

export function EditEatingLogEntry() {
  const { goBack, setOptions } =
    useNavigation<StackScreenProps<'EditEatingLogEntry' | 'NewEatingLogEntry'>['navigation']>();
  const { params } =
    useRoute<StackScreenProps<'EditEatingLogEntry' | 'NewEatingLogEntry'>['route']>();
  const { data: currUserData } = useCurrentUser();
  const resumableUploadManager = useContext(ResumableUploadManagerContext);

  const patientID = currUserData?.user?.role?.ID!;
  const practiceID = 'practiceID' in params ? params.practiceID : undefined;
  const client = useApolloClient();

  const { data, loading, refetch } = useQuery(EditEatingLogEntryQuery, {
    variables: practiceID ? { practiceID } : null!,
    skip: !practiceID,
  });

  const { theme } = useTheme();

  const role = data?.user?.role;

  const hasExistingImage =
    role && 'eatingLogEntryByID' in role ? !!role.eatingLogEntryByID?.imageUrl : false;
  const defaultValues = useMemo<Partial<z.output<typeof Schema>>>(() => {
    const existingEntry = role && 'eatingLogEntryByID' in role ? role.eatingLogEntryByID : null;
    return existingEntry
      ? {
          binged: existingEntry.eatingLogEntry.binged,
          description: existingEntry.eatingLogEntry.description,
          meal: existingEntry.eatingLogEntry.meal,
          date: formatGQLDate(parseGQLDateTime(existingEntry.eatingLogEntry.timestamp)),
          time: formatGQLTime(parseGQLDateTime(existingEntry.eatingLogEntry.timestamp)),
          image: existingEntry.imageUrl,
        }
      : {
          meal: 'meal' in params ? params.meal : undefined,
          date: 'date' in params && params.date ? params.date : formatGQLDate(),
          time:
            'timeOfDay' in params
              ? params.timeOfDay
              : formatGQLTime(roundToNearestMinutes(new Date(), { nearestTo: 15 })),
        };
  }, [params, role]);

  const form = useZodForm(Schema, {
    defaultValues,
  });
  const meal = form.watch('meal');

  const handleSubmit = form.handleSubmit;

  const onSave = useCallback<ReturnType<typeof handleSubmit>>(
    (e) => {
      return handleSubmit(async (data) => {
        const assetDetails = data.image ? getImageInputAssetDetails(data.image) : undefined;
        const practiceValues = { patientID, ratings: [] };
        const imageContentType = assetDetails?.mimeType || 'image/jpeg';
        const eatingLogEntry = {
          binged: data.binged,
          meal: data.meal!,
          timestamp: parseGQLDateAndTime(data.date, data.time).toISOString() as GQLDateTime,
          description: data.description,
        } satisfies EatingLogEntryInput;

        const response = await (practiceID
          ? client.mutate({
              mutation: EditEatingLogEntryUpdateMutation,
              variables: {
                imageContentType,
                input: {
                  practiceID,
                  practiceValues,
                  eatingLogEntry,
                  deleteImage: hasExistingImage && !data.image,
                },
              },
              refetchQueries: [
                'EatingLogEntriesList' as EatingLogEntriesListQueryName,
                'EatingLogCalendar' as EatingLogCalendarQueryName,
              ],
            })
          : client.mutate({
              mutation: EditEatingLogEntryCreateMutation,
              variables: {
                imageContentType,
                input: {
                  practiceValues,
                  eatingLogEntry,
                },
              },
              refetchQueries: [
                'EatingLogEntriesList' as EatingLogEntriesListQueryName,
                'EatingLogCalendar' as EatingLogCalendarQueryName,
              ],
            }));

        return new Promise<void>((res, rej) => {
          function onSuccess() {
            // let form settle for UnsavedChangesModal
            setTimeout(() => {
              goBack();
            }, 10);
            res();
          }

          if (response.data) {
            if (assetDetails && data.image) {
              resumableUploadManager
                .uploadFile(data.image, response.data.eatingLogEntry.imageUploadUrl as SessionUri)
                .then((id) => {
                  const remove = resumableUploadManager.addListener(id, ({ percent }) => {
                    if (percent === 100) {
                      remove();
                      // update image url with delay so that GCS syncs to our API
                      setTimeout(() => {
                        refetch({ practiceID: response.data!.eatingLogEntry.practiceID }).then(
                          () => {
                            onSuccess();
                          },
                        );
                      }, 1000);
                    }
                  });
                })
                .catch(rej);
            } else {
              onSuccess();
            }
          }
        });
      })(e);
    },
    [
      handleSubmit,
      practiceID,
      client,
      patientID,
      goBack,
      resumableUploadManager,
      hasExistingImage,
      refetch,
    ],
  );

  useLayoutEffect(() => {
    setOptions({
      headerLeft: ({ tintColor }) => (
        <HeaderButtons left>
          <HeaderItem
            title=""
            aria-label="Cancel"
            iconName="close"
            color={tintColor}
            onPress={goBack}
          />
        </HeaderButtons>
      ),
      title: 'Eating log',
      headerRight: () => (
        <HeaderButtons>
          <Button text="Done" onPress={onSave} />
        </HeaderButtons>
      ),
    });
  }, [setOptions, onSave, goBack]);

  if (loading) return <ActivityIndicator />;

  return (
    <ScrollView
      style={{ flex: 1 }}
      contentContainerStyle={{
        paddingBottom: 100,
        flexGrow: 1,
      }}
      testID="EditEatingLogEntry_scrollView"
    >
      <FormContainer {...form}>
        <View
          style={{
            paddingHorizontal: 15,
            paddingVertical: 12,
            backgroundColor: theme.color.accentThree300,
            gap: 10,
          }}
          row
        >
          <View flex={1}>
            <DateFormInput
              control={form.control}
              name="date"
              mode="date"
              aria-label="Date"
              formatOptions={{ month: 'short' }}
            />
          </View>
          <View flex={1}>
            <DateFormInput
              control={form.control}
              name="time"
              mode="time"
              aria-label="Time"
              minuteInterval={1}
            />
          </View>
        </View>
        <View style={{ paddingHorizontal: 38, paddingVertical: 25, gap: 25 }}>
          <SegmentedControlFormInput
            control={form.control}
            name="meal"
            mode="single"
            required
            label="Meal"
            items={[
              EatingLogMealType.BREAKFAST,
              EatingLogMealType.LUNCH,
              EatingLogMealType.DINNER,
              EatingLogMealType.SNACK,
            ].map((mealType) => ({
              ...getEatingLogMealTypeContent(mealType),
              value: mealType,
            }))}
          />
          <View style={{ gap: 5 }}>
            <ImageFormInput
              control={form.control}
              name="image"
              label="What you ate"
              chooseImageLabel="Add photo"
              changeImageLabel="Replace photo"
            />
            <TextFormInput
              control={form.control}
              name="description"
              multiline
              initialContentHeight={70}
              aria-label="Description"
              placeholder="Or describe what you had and other details"
            />
          </View>
          <Controller
            control={form.control}
            name="binged"
            render={(props) => (
              <AccessibleInput
                label="Did you binge"
                hintTop={`Did you lose control over eating during this ${meal ? getEatingLogMealTypeContent(meal).label.toLowerCase() : 'meal'}?`}
                error={props.fieldState.error?.message}
                required
              >
                {() => (
                  <View row style={{ gap: 10, marginTop: 10 }}>
                    <Button
                      text="Yes"
                      alignSelf="stretch"
                      variant={props.field.value === true ? 'solid' : 'contained'}
                      onPress={() => props.field.onChange(true)}
                      style={{ flex: 1 }}
                    />
                    <Button
                      text="No"
                      alignSelf="stretch"
                      variant={props.field.value === false ? 'solid' : 'contained'}
                      onPress={() => props.field.onChange(false)}
                      style={{ flex: 1 }}
                    />
                  </View>
                )}
              </AccessibleInput>
            )}
          />
        </View>
      </FormContainer>
      <UnsavedChangesModal
        hasUnsavedChanges={form.formState.isDirty && !form.formState.isSubmitSuccessful}
        description="You’ve added info to this meal entry. Would you like to continue editing?"
        confirmText="Continue"
        onConfirm={async () => {}}
      />
    </ScrollView>
  );
}
