import { Button } from '@oui/app-core/src/components/Button';
import { View } from '@oui/app-core/src/components/View';

import { useBingeEatingMyPlanContext } from '../';

export function MoodPicker(props: {
  options: { label: string; active: boolean }[];
  onPress: (value: string) => void;
}) {
  const { primaryColor } = useBingeEatingMyPlanContext();

  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 10,
      }}
    >
      {props.options.map((option) => (
        <Button
          key={option.label}
          variant={option.active ? 'solid' : 'contained'}
          color={option.active ? undefined : primaryColor}
          _accentColor={primaryColor}
          text={option.label}
          onPress={() => {
            props.onPress(option.label);
          }}
        />
      ))}
    </View>
  );
}
