import { PracticeTile } from '@oui/app-core/src/components/PracticeTile';
import { useI18n } from '@oui/app-core/src/lib/i18n';

import { useBingeEatingMyPlanContext } from '../';

type BingeEatingMyPlanTileProps = {
  lockedUntil?: number;
  practiceCount?: number;
  onPress: () => unknown;
};

export const BingeEatingMyPlanTile: React.FC<BingeEatingMyPlanTileProps> = ({
  onPress,
  lockedUntil,
  practiceCount,
}) => {
  const { $t } = useI18n();
  const bingeEatingMyPlanTheme = useBingeEatingMyPlanContext();

  return (
    <PracticeTile
      testID="PracticeTile_bingeEatingMyPlan"
      title={$t({ id: 'PracticeTile_bingeEatingMyPlan_title', defaultMessage: 'MyPlan' })}
      description={$t({
        id: 'PracticeTile_bingeEatingMyPlan_description',
        defaultMessage: 'Know how to cope without food',
      })}
      color={bingeEatingMyPlanTheme.backgroundColor}
      icon="bulleted-list"
      onPress={onPress}
      lockedUntil={lockedUntil}
      practiceCount={practiceCount}
    />
  );
};
