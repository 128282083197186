import { IntlShape } from '@formatjs/intl';

import { SupporterTypeEnum } from './types/avro';

export function getPatientSocialSupporterRelation({
  $t,
  socialSupportRelation,
}: {
  $t: IntlShape['$t'];
  socialSupportRelation?: SupporterTypeEnum | 'DETRACTOR';
}) {
  switch (socialSupportRelation) {
    case SupporterTypeEnum.CHEERLEADER:
      return $t({
        id: 'patientSocialSupporterRelation_CHEERLEADER',
        defaultMessage: 'Cheerleader',
      });
    case SupporterTypeEnum.PARTNER:
      return $t({
        id: 'patientSocialSupporterRelation_PARTNER',
        defaultMessage: 'Partner',
      });
    case SupporterTypeEnum.POSITIVE_ENABLER:
      return $t({
        id: 'patientSocialSupporterRelation_POSITIVE_ENABLER',
        defaultMessage: 'Positive Enabler',
      });
    case 'DETRACTOR':
      return $t({
        id: 'patientSocialSupporterRelation_DETRACTOR',
        defaultMessage: 'Detractor',
      });
    default:
      return '';
  }
}
