import { useMutation, useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import addHours from 'date-fns/addHours';
import produce from 'immer';
import get from 'lodash/get';
import { ComponentProps, useEffect, useMemo } from 'react';
import * as z from 'zod';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Button } from '@oui/app-core/src/components/Button';
import { HeaderButtons } from '@oui/app-core/src/components/HeaderButtons';
import { Icon } from '@oui/app-core/src/components/Icon';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { Label, Text } from '@oui/app-core/src/components/Text';
import { UnsavedChangesModal } from '@oui/app-core/src/components/UnsavedChangesModal';
import { View } from '@oui/app-core/src/components/View';
import {
  Control,
  FieldPath,
  FieldValues,
  FormContainer,
  NumberFormInput,
  PickerFormInput,
  TimeFormInput,
  useFieldArray,
  useZodForm,
} from '@oui/app-core/src/form';
import { useArtifactRequest } from '@oui/app-core/src/hooks/useArtifactResult';
import { useTheme } from '@oui/app-core/src/styles';
import { getPrecedingMealTypeForSnack, getSnackLabelForTimeOfDay } from '@oui/lib/src/eatingLog';
import { formatGQLTime, parseGQLTime } from '@oui/lib/src/gqlDate';
import { graphql } from '@oui/lib/src/graphql/tada';
import { EatingSchedule, Reminder } from '@oui/lib/src/types/avro';

import { StackScreenProps } from '../types/navigation';

function addHoursToTimeOfDay(timeOfDay: string, add: number) {
  const date = parseGQLTime(timeOfDay);
  const newDate = addHours(date, add);
  // Detect if we've gone to the next day
  if (newDate.getHours() < date.getHours()) return '23:59';
  return formatGQLTime(newDate);
}

function getFormRemindersForEatingSchedule(
  eatingSchedule?: EatingSchedule,
): z.output<typeof Schema>['reminders'] {
  if (!eatingSchedule) return [];

  function scheduleReminderToFormReminder(
    r: Reminder,
    path: string,
  ): z.output<typeof Schema>['reminders'][0] {
    return {
      path,
      quantity: Math.abs(r.delay),
      unit:
        r.delay < 0
          ? r.delayUnit === 'minutes'
            ? ('minutes-before' as const)
            : ('hours-before' as const)
          : r.delayUnit === 'hours'
            ? ('minutes-after' as const)
            : ('hours-after' as const),
    };
  }

  return [
    ...eatingSchedule.meals.breakfast.reminders.map((r) =>
      scheduleReminderToFormReminder(r, 'meals.breakfast'),
    ),
    ...eatingSchedule.meals.lunch.reminders.map((r) =>
      scheduleReminderToFormReminder(r, 'meals.lunch'),
    ),
    ...eatingSchedule.meals.dinner.reminders.map((r) =>
      scheduleReminderToFormReminder(r, 'meals.dinner'),
    ),
    ...eatingSchedule.snacks.flatMap((snack, index) => {
      return snack.reminders.map((r) => scheduleReminderToFormReminder(r, `snacks.${index}`));
    }),
  ];
}

function SectionHeading({
  icon,
  text,
}: {
  icon: ComponentProps<typeof Icon>['name'];
  text: string;
}) {
  const { theme } = useTheme();
  return (
    <View row style={{ gap: 10 }}>
      <Icon name={icon} color={theme.color.accentTwo100} />
      <Text text={text} size={15} weight="semibold" color={theme.color.gray300} />
    </View>
  );
}

function Hint({ text }: { text: string }) {
  const { theme } = useTheme();
  return <Text text={text} color={theme.color.gray300} size={15} />;
}

function HorizontalTimeInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  label,
  onRemove,
  ...rest
}: {
  name: TName;
  control: Control<TFieldValues>;
  label: string;
  onRemove?: () => void;
} & Partial<Omit<ComponentProps<typeof TimeFormInput>, 'control' | 'name' | 'label'>>) {
  const { theme } = useTheme();
  return (
    <View row style={{ justifyContent: 'space-between' }} spacing={10}>
      <View aria-hidden row flex={1}>
        <Label text={label} />
      </View>
      <TimeFormInput
        control={control}
        name={name}
        aria-label={label}
        style={{ flex: 1 }}
        minuteInterval={1}
        {...rest}
      />
      {onRemove ? (
        <Icon
          size={16}
          name="close"
          aria-label={`Remove ${label}`}
          onPress={onRemove}
          color={theme.color.gray500}
        />
      ) : (
        <Icon size={16} name="close" style={{ opacity: 0 }} />
      )}
    </View>
  );
}

const Schema = z.object({
  schedule: EatingSchedule,
  reminders: z.array(
    z.object({
      path: z.string().min(1, 'Must select a meal or snack'),
      quantity: z.coerce.number().int(),
      unit: z.enum(['minutes-before', 'minutes-after', 'hours-before', 'hours-after']),
    }),
  ),
});

export const EditEatingScheduleQuery = graphql(`
  query EditEatingSchedule {
    user {
      ID
      role {
        ID
        roleType
        product {
          ID
          slug
        }
        eatingScheduleComposition {
          ID
          sections {
            ID
            json
          }
        }
      }
    }
  }
`);

export const EditEatingScheduleCreateMutation = graphql(`
  mutation EditEatingScheduleCreateMutation($patientID: UUID!) {
    newCompositionWithTemplate(
      template: EATING_SCHEDULE
      title: "EATING_SCHEDULE"
      patientID: $patientID
    ) {
      ID
      sections {
        ID
        json
      }
    }
  }
`);

export const EditEatingScheduleUpdateMutation = graphql(`
  mutation EditEatingScheduleUpdateMutation($sectionID: UUID!, $text: Any!) {
    updateCompositionSection(sectionID: $sectionID, text: $text) {
      ID
      json
    }
  }
`);

export function EditEatingSchedule() {
  const { data, loading, error, refetch } = useQuery(EditEatingScheduleQuery);
  const [createComposition, { data: createData }] = useMutation(EditEatingScheduleCreateMutation);
  const [updateSection] = useMutation(EditEatingScheduleUpdateMutation);
  const { goBack, setOptions } =
    useNavigation<StackScreenProps<'EditEatingSchedule'>['navigation']>();
  const { theme } = useTheme();

  const role = data?.user?.role;

  useEffect(() => {
    if (role && !role?.eatingScheduleComposition && !loading && !error) {
      void createComposition({ variables: { patientID: role.ID } });
      void refetch();
    }
  }, [role, loading, error, createComposition, refetch]);

  const eatingScheduleSection =
    role?.eatingScheduleComposition?.sections[0] ||
    createData?.newCompositionWithTemplate.sections[0];
  const eatingSchedule = eatingScheduleSection
    ? EatingSchedule.parse(eatingScheduleSection.json)
    : undefined;

  const form = useZodForm(Schema, {
    mode: 'onChange',
    defaultValues: {
      schedule: eatingSchedule,
      reminders: getFormRemindersForEatingSchedule(eatingSchedule),
    },
  });

  useArtifactRequest('EditEatingSchedule', form.formState.isSubmitSuccessful);

  const handleSubmit = form.handleSubmit;

  const onSave = useMemo(() => {
    return handleSubmit(async (data) => {
      const text = produce(data.schedule, (draft) => {
        // clear existing reminders
        draft.meals.breakfast.reminders = [];
        draft.meals.lunch.reminders = [];
        draft.meals.dinner.reminders = [];
        draft.snacks.forEach((s) => (s.reminders = []));

        // set schedule reminders from form state
        for (let reminder of data.reminders) {
          get(draft, reminder.path as 'meals.breakfast').reminders.push({
            delay: reminder.unit.includes('before') ? -reminder.quantity : reminder.quantity,
            delayUnit: ['minutes-after', 'minutes-before'].includes(reminder.unit)
              ? 'minutes'
              : 'hours',
          });
        }
      });

      await updateSection({
        variables: { sectionID: eatingScheduleSection?.ID!, text },
      });

      // Allow UnsavedChangesModal hasUnsavedChanges and isSubmitSuccessful
      // to change back before attempting navigation
      setTimeout(goBack, 50);
    });
  }, [handleSubmit, eatingScheduleSection, updateSection, goBack]);

  useEffect(() => {
    setOptions({
      headerRight: () => (
        <HeaderButtons>
          <Button text="Save" onPress={onSave} />
        </HeaderButtons>
      ),
    });
  }, [setOptions, onSave]);

  const snackFields = useFieldArray({ control: form.control, name: 'schedule.snacks' });
  const values = form.watch();

  function addSnack() {
    const snackTimes = snackFields.fields.map((snack) =>
      getPrecedingMealTypeForSnack(values.schedule, snack.time),
    );

    if (!snackTimes.includes('BREAKFAST')) {
      snackFields.append({
        time: addHoursToTimeOfDay(values.schedule.meals.breakfast.time, 3),
        reminders: [],
      });
    } else if (!snackTimes.includes('LUNCH')) {
      snackFields.append({
        time: addHoursToTimeOfDay(values.schedule.meals.lunch.time, 3),
        reminders: [],
      });
    } else {
      snackFields.append({
        time: addHoursToTimeOfDay(values.schedule.meals.dinner.time, 3),
        reminders: [],
      });
    }
  }

  const reminderFields = useFieldArray({ control: form.control, name: 'reminders' });
  function addReminder() {
    reminderFields.append({ path: '', quantity: 5, unit: 'minutes-before' });
  }

  return (
    <ScrollView
      style={{ flex: 1, backgroundColor: theme.color.gray800 }}
      contentContainerStyle={{
        padding: 18,
        paddingBottom: 100,
        flexGrow: 1,
      }}
      testID="EditEatingSchedule_scrollView"
    >
      {eatingSchedule ? (
        <FormContainer {...form}>
          <View style={{ gap: 15 }}>
            <Hint text="A regular pattern of eating includes 3 meals and 2-3 snacks a day." />
            <View style={{ gap: 5 }}>
              <SectionHeading icon="meal" text="Meals" />
              <Hint text="Your eating schedule should include 3 meals a day." />
            </View>
            <View style={{ gap: 15 }}>
              <HorizontalTimeInput
                name="schedule.meals.breakfast.time"
                control={form.control}
                label="Breakfast"
              />
              <HorizontalTimeInput
                name="schedule.meals.lunch.time"
                control={form.control}
                label="Lunch"
              />
              <HorizontalTimeInput
                name="schedule.meals.dinner.time"
                control={form.control}
                label="Dinner"
              />
            </View>
          </View>
          <View style={{ height: 1, backgroundColor: '#DEE0E5', marginVertical: 25 }} />
          <View style={{ gap: 15 }}>
            <View style={{ gap: 5 }}>
              <SectionHeading icon="apple" text="Snacks" />
              <Hint text="Your eating schedule should include at least one snack a day." />
            </View>
            {snackFields.fields.map((field, index) => {
              return (
                <HorizontalTimeInput
                  key={field.id}
                  name={`schedule.snacks.${index}.time`}
                  control={form.control}
                  label={getSnackLabelForTimeOfDay(values.schedule, field.time)}
                  onRemove={
                    snackFields.fields.length > 1 ? () => snackFields.remove(index) : undefined
                  }
                />
              );
            })}
            {snackFields.fields.length < 3 ? (
              <Button
                text="Add snack"
                icon="plus"
                onPress={addSnack}
                variant="text"
                alignSelf="flex-start"
                size="small"
              />
            ) : null}
          </View>
          <View
            style={{
              height: 1,
              backgroundColor: '#DEE0E5',
              marginVertical: 25,
              marginHorizontal: -18,
            }}
          />
          <View style={{ gap: 15 }}>
            <Text
              text="Optional reminders"
              size={17}
              weight="semibold"
              color={theme.color.gray300}
            />
            <Hint text="Send push notifications to remind yourself at each planned mealtime." />
            {reminderFields.fields.map((field, index) => {
              return (
                <View key={field.id} style={{ gap: 10 }}>
                  <View row style={{ gap: 15 }}>
                    <Icon name="notification" color={theme.color.gray500} />
                    <View style={{ flex: 1, maxWidth: 80 }}>
                      <NumberFormInput
                        control={form.control}
                        name={`reminders.${index}.quantity`}
                        placeholder="#"
                        aria-label="Number"
                        inputMode="numeric"
                      />
                    </View>
                    <View style={{ flex: 1 }}>
                      <PickerFormInput
                        control={form.control}
                        name={`reminders.${index}.unit`}
                        aria-label="Unit"
                        items={[
                          { label: 'Minutes before', value: 'minutes-before' },
                          { label: 'Minutes after', value: 'minutes-after' },
                          { label: 'Hours before', value: 'hours-before' },
                          { label: 'Hours after', value: 'hours-after' },
                        ]}
                      />
                    </View>
                    <Icon
                      name="close"
                      size={16}
                      color={theme.color.gray500}
                      aria-label="Remove reminder"
                      onPress={() => reminderFields.remove(index)}
                    />
                  </View>
                  <View row style={{ gap: 15 }}>
                    <Icon name="notification" color={theme.color.gray500} style={{ opacity: 0 }} />
                    <View style={{ flex: 1 }}>
                      <PickerFormInput
                        control={form.control}
                        name={`reminders.${index}.path`}
                        placeholder="Select meal or snack"
                        aria-label="Meal or snack"
                        items={[
                          { label: 'Breakfast', value: 'meals.breakfast' },
                          { label: 'Lunch', value: 'meals.lunch' },
                          { label: 'Dinner', value: 'meals.dinner' },
                          ...values.schedule.snacks.map((snack, i) => ({
                            label: getSnackLabelForTimeOfDay(values.schedule, snack.time),
                            value: `snacks.${i}`,
                          })),
                        ]}
                      />
                    </View>
                    <Icon
                      name="notification"
                      color={theme.color.gray500}
                      style={{ opacity: 0 }}
                      size={16}
                    />
                  </View>
                </View>
              );
            })}
            <Button icon="plus" text="Add notification" variant="text" onPress={addReminder} />
          </View>
          <UnsavedChangesModal
            hasUnsavedChanges={form.formState.isDirty && !form.formState.isSubmitSuccessful}
            description="You’ve added info to this eating schedule. Would you like to save it before leaving?"
            onConfirm={async ({ continueNavigation }) => {
              await onSave();
              continueNavigation();
            }}
          />
        </FormContainer>
      ) : (
        <ActivityIndicator />
      )}
    </ScrollView>
  );
}
