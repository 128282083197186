import { useMutation } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';

import { Button } from '@oui/app-core/src/components/Button';
import { ConfirmationModal } from '@oui/app-core/src/components/ConfirmationModal';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { formatDateToWeekRange } from '@oui/lib/src/formatDateToWeekRange';
import {
  getEatingCommitmentHealthyFoodLabel,
  getEatingCommitmentUnhealthyFoodLabel,
} from '@oui/lib/src/getEatingCommitmentEnumLabel';
import { FragmentOf, graphql, readFragment } from '@oui/lib/src/graphql/tada';

export const CommitmentCheckInUpdatePracticeMutation = graphql(`
  mutation CommitmentCheckInUpdatePractice($input: MutationUpdatePracticeInput!) {
    updatePractice(input: $input) {
      ... on UpdatePracticeResult {
        practice {
          practiceID
          ... on EatingCommitmentEntryPractice {
            practiceID
            eatingCommitmentEntry {
              decreaseSuccess
              decreaseCommitment
              increaseSuccess
              increaseCommitment
            }
          }
        }
      }
    }
  }
`);

export const CommitmentCheckInFragment = graphql(`
  fragment CommitmentCheckIn on EatingCommitmentEntryPractice {
    practiceID
    eatingCommitmentEntry {
      decreaseCommitment
      decreaseSuccess
      increaseCommitment
      increaseSuccess
    }
    practiceValues {
      date
    }
  }
`);

export const CommitmentCheckIn = ({
  entry,
  onClose,
}: {
  entry: FragmentOf<typeof CommitmentCheckInFragment>;
  onClose: () => void;
}) => {
  const data = readFragment(CommitmentCheckInFragment, entry);
  const { $t } = useI18n();
  const [updatePractice] = useMutation(CommitmentCheckInUpdatePracticeMutation);
  const [checkInState, setCheckInState] = useState({
    increaseSuccess: null as boolean | null,
    decreaseSuccess: null as boolean | null,
  });

  const currentStep = useMemo(() => {
    if (checkInState.increaseSuccess === null) return 'increase';
    if (checkInState.decreaseSuccess === null) return 'decrease';
    return null;
  }, [checkInState.increaseSuccess, checkInState.decreaseSuccess]);

  useEffect(() => {
    if (checkInState.increaseSuccess !== null || checkInState.decreaseSuccess !== null) {
      updatePractice({
        variables: {
          input: {
            practiceID: data.practiceID,
            content: {
              eatingCommitmentEntry: {
                ...checkInState,
                decreaseCommitment: data.eatingCommitmentEntry.decreaseCommitment,
                increaseCommitment: data.eatingCommitmentEntry.increaseCommitment,
              },
            },
          },
        },
      });
    }

    if (checkInState.decreaseSuccess !== null && checkInState.increaseSuccess !== null) {
      onClose();
    }
  }, [checkInState, updatePractice, data, onClose]);

  const date = formatDateToWeekRange(data.practiceValues.date);

  const modalData = {
    increase: {
      title: 'Increase commitment',
      description: `Last week, ${date}, were you able to meet your commitment to...`,
      commitment: $t(
        {
          id: 'CommitmentCheckIn_increased',
          defaultMessage: 'Increase {healthyFood}',
        },
        {
          healthyFood: getEatingCommitmentHealthyFoodLabel({
            $t,
            eatingCommitmentHealthyFood: data.eatingCommitmentEntry.increaseCommitment,
          }),
        },
      ),
    },
    decrease: {
      title: 'Decrease commitment',
      description: `Last week, ${date}, were you able to meet your commitment to...`,
      commitment: $t(
        {
          id: 'CommitmentCheckIn_reduced',
          defaultMessage: 'Decrease {unhealthyFood}',
        },
        {
          unhealthyFood: getEatingCommitmentUnhealthyFoodLabel({
            $t,
            eatingCommitmentUnhealthyFood: data.eatingCommitmentEntry.decreaseCommitment,
          }),
        },
      ),
    },
  };

  const handleResponse = (response: 'yes' | 'no') => {
    setCheckInState((prevState) => ({
      ...prevState,
      [currentStep === 'increase' ? 'increaseSuccess' : 'decreaseSuccess']: response === 'yes',
    }));
  };

  if (!currentStep) return null;

  const { title, description, commitment } = modalData[currentStep];

  return (
    <ConfirmationModal
      showCloseIcon
      onCancel={onClose}
      visible
      title={title}
      description={description}
      onConfirm={() => {}}
      actions={
        <View
          row
          style={{
            justifyContent: 'space-between',
            gap: 16,
            flex: 1,
          }}
        >
          <Button
            style={{ flex: 1 }}
            onPress={() => handleResponse('yes')}
            text="Yes"
            variant="contained"
          />
          <Button
            style={{ flex: 1 }}
            onPress={() => handleResponse('no')}
            text="No"
            variant="contained"
          />
        </View>
      }
    >
      <Text
        style={{
          marginTop: 16,
          textAlign: 'center',
        }}
        weight="semibold"
        text={commitment}
      />
    </ConfirmationModal>
  );
};
