import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useProgress } from '@oui/app-core/src/hooks/useCurrentUser';
import { useDailyState } from '@oui/app-core/src/hooks/useDailyState';

import { DailyCheckInModal } from '../DailyCheckIn/DailyCheckInModal/DailyCheckInModal';

type DailyCheckInContextType = {
  onShowDailyCheckIn: (eatingCheckingTrigger: 'EATING_LOG' | 'SESSION') => void;
  onHideDailyCheckIn: () => void;
};

const DailyCheckInContext = createContext<DailyCheckInContextType>({
  onShowDailyCheckIn: () => {},
  onHideDailyCheckIn: () => {},
});

export function DailyCheckInProvider({ children }: PropsWithChildren) {
  const { data: progressData } = useProgress();
  const initialCompletedSessionsRef = useRef<number | null>(null);
  const [eatingCheckingTrigger, setEatingCheckingTrigger] = useState<
    'EATING_LOG' | 'SESSION' | undefined
  >();

  const [dailyCheckinShown, setDailyCheckinShown] = useDailyState<boolean>(
    'dailyCheckinShown',
    false,
  );

  const onShowDailyCheckIn = useCallback(
    (eatingCheckingTrigger: 'EATING_LOG' | 'SESSION') => {
      if (!dailyCheckinShown) {
        setEatingCheckingTrigger(eatingCheckingTrigger);
        setDailyCheckinShown(true);
      }
    },
    [dailyCheckinShown, setDailyCheckinShown],
  );

  const onHideDailyCheckIn = useCallback(() => {
    setEatingCheckingTrigger(undefined);
  }, []);

  useEffect(() => {
    if (progressData?.user?.role?.progress) {
      const currentCompletedSessions = progressData.user.role.progress.filter(
        (p) => p.completed,
      ).length;

      if (initialCompletedSessionsRef.current === null) {
        initialCompletedSessionsRef.current = currentCompletedSessions;
      } else if (currentCompletedSessions > initialCompletedSessionsRef.current) {
        onShowDailyCheckIn('SESSION');
        initialCompletedSessionsRef.current = currentCompletedSessions;
      }
    }
  }, [progressData, onShowDailyCheckIn]);

  const contextValue = useMemo<DailyCheckInContextType>(
    () => ({
      onShowDailyCheckIn,
      onHideDailyCheckIn,
    }),
    [onShowDailyCheckIn, onHideDailyCheckIn],
  );

  return (
    <DailyCheckInContext.Provider value={contextValue}>
      {children}
      <DailyCheckInModal trigger={eatingCheckingTrigger} />
    </DailyCheckInContext.Provider>
  );
}

export function useDailyCheckInContext() {
  return useContext(DailyCheckInContext);
}
