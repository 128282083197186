import { createContext, PropsWithChildren, useContext } from 'react';

import { useTheme } from '@oui/app-core/src/styles';

type bingeEatingMyPlanTheme = {
  backgroundColor: string;
  primaryColor: string;
  secondaryColor: string;
};

const BingeEatingMyPlanContext = createContext<bingeEatingMyPlanTheme>({
  backgroundColor: 'black',
  primaryColor: 'black',
  secondaryColor: 'black',
});

export function BingeEatingMyPlanProvider({
  children,
  ...props
}: PropsWithChildren<Partial<bingeEatingMyPlanTheme>>) {
  const { theme } = useTheme();
  return (
    <BingeEatingMyPlanContext.Provider
      value={{
        backgroundColor: theme.color.accentTwo100,
        primaryColor: theme.color.primary100,
        secondaryColor: theme.color.accent300,
        ...props,
      }}
    >
      {children}
    </BingeEatingMyPlanContext.Provider>
  );
}

export function useBingeEatingMyPlanContext() {
  return useContext(BingeEatingMyPlanContext);
}
