import { Image } from 'react-native';

import { Icon } from '@oui/app-core/src/components/Icon';
import { Heading, Label, Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useTheme } from '@oui/app-core/src/styles';
import { getEatingLogMealTypeContent } from '@oui/lib/src/eatingLog';
import { formatDate } from '@oui/lib/src/formatDate';
import { FragmentOf, graphql, readFragment } from '@oui/lib/src/graphql/tada';

export const EatingLogEntryFragment = graphql(`
  fragment EatingLogEntry on EatingLogEntryPractice {
    practiceID
    imageUrl
    eatingLogEntry {
      binged
      description
      meal
      timestamp
    }
  }
`);

export const EatingLogEntry = ({
  showTimestamp,
  thirdPerson,
  practice,
}: {
  thirdPerson?: boolean;
  showTimestamp?: boolean;
  practice: FragmentOf<typeof EatingLogEntryFragment>;
}) => {
  const { theme } = useTheme();
  const data = readFragment(EatingLogEntryFragment, practice);
  const ateLabel = thirdPerson ? 'Ate' : 'What you ate';
  const content = getEatingLogMealTypeContent(data.eatingLogEntry.meal);

  return (
    <View style={{ gap: 15 }}>
      {showTimestamp ? (
        <Heading
          level={3}
          text={formatDate({ originalDate: data.eatingLogEntry.timestamp })}
          style={{ marginBottom: 10 }}
        />
      ) : null}
      <View row childFlex={1}>
        <Label text="Meal" />
        <View row style={{ gap: 5 }}>
          <Icon name={content.icon} color={theme.color.accentTwo100} size={16} />
          <Text text={content.label} />
        </View>
      </View>
      {data.imageUrl ? (
        <View style={{ gap: 5, height: 375 }}>
          <Label text={ateLabel} />
          <Image
            source={{ uri: data.imageUrl }}
            style={{ flex: 1, height: '100%', borderRadius: 10 }}
            resizeMode="cover"
          />
          <Text text={data.eatingLogEntry.description} />
        </View>
      ) : (
        <View row childFlex={1}>
          <Label text={ateLabel} />
          <Text text={data.eatingLogEntry.description} />
        </View>
      )}
      <View row childFlex={1}>
        <Label text="Binged" />
        {data.eatingLogEntry.binged ? (
          <Text size={15} weight="semibold" text="Yes" color={theme.color.danger} />
        ) : (
          <Text text="No" />
        )}
      </View>
    </View>
  );
};
