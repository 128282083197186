import { useNavigation } from '@react-navigation/native';
import { useCallback, useState } from 'react';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { UnsavedChangesModal } from '@oui/app-core/src/components/UnsavedChangesModal';
import { useUpdateCompositionSection } from '@oui/app-core/src/hooks/useComposition';
import { useI18n } from '@oui/app-core/src/lib/i18n';

import { SocialSupport } from '../../components';
import { useBingeEatingMyPlanComposition, useScreenNavigation } from '../../hooks';

export function EditSocialSupport() {
  const { $t } = useI18n();
  const navigation = useNavigation();

  const { data, loading } = useBingeEatingMyPlanComposition({
    createIfUndefined: false,
  });

  const [update] = useUpdateCompositionSection();

  const [unsavedData, setUnsavedData] = useState<
    Partial<NonNullable<typeof data>['sections']['SOCIAL_SUPPORT']>
  >({});

  const onEditMyPlan = useCallback((toMerge: typeof unsavedData) => {
    setUnsavedData((curr) => ({ ...curr, ...toMerge }));
  }, []);

  const onSave = useCallback(async () => {
    return update({
      variables: {
        sectionID: data!.sections['SOCIAL_SUPPORT'].ID!,
        text: unsavedData.json,
      },
    }).then(() => {
      setUnsavedData({});
      setTimeout(() => navigation.navigate('BingeEatingMyPlan'), 10);
    });
  }, [navigation, unsavedData, update, data]);

  const onClose = useCallback(() => {
    navigation.navigate('BingeEatingMyPlan');
  }, [navigation]);

  useScreenNavigation({
    onClose,
    onSave,
  });

  return data ? (
    <>
      <SocialSupport
        data={{ ...data['sections']['SOCIAL_SUPPORT'], ...unsavedData }}
        onEdit={onEditMyPlan}
      />

      <UnsavedChangesModal
        hasUnsavedChanges={Object.keys(unsavedData).length > 0}
        onConfirm={({ continueNavigation }) => {
          return onSave().then(() => {
            continueNavigation();
          });
        }}
        confirmText={$t({
          id: 'EditSocialSupport_confirmationModal_confirmButton',
          defaultMessage: 'Save',
        })}
        cancelText={$t({
          id: 'EditSocialSupport_confirmationModal_cancelButton',
          defaultMessage: 'Discard',
        })}
        title={$t({
          id: 'EditSocialSupport_confirmationModal_title',
          defaultMessage: 'Save changes?',
        })}
        description={$t({
          id: 'EditSocialSupport_confirmationModal_description',
          defaultMessage: "You've made edits to MyPlan. Would you like to save them?",
        })}
      />
    </>
  ) : loading ? (
    <ActivityIndicator />
  ) : null;
}
