import { IntlShape } from '@formatjs/intl';

import { EatingCommitmentHealthyFoodEnum, EatingCommitmentUnhealthyFoodEnum } from './types/avro';

export function getEatingCommitmentHealthyFoodLabel({
  $t,
  eatingCommitmentHealthyFood,
}: {
  $t: IntlShape['$t'];
  eatingCommitmentHealthyFood: EatingCommitmentHealthyFoodEnum;
}) {
  switch (eatingCommitmentHealthyFood) {
    case EatingCommitmentHealthyFoodEnum.FRUITS:
      return $t({ id: 'EatingCommitmentHealthyFood_fruits', defaultMessage: 'Fruits' });
    case EatingCommitmentHealthyFoodEnum.VEGETABLES:
      return $t({ id: 'EatingCommitmentHealthyFood_vegetables', defaultMessage: 'Vegetables' });
    case EatingCommitmentHealthyFoodEnum.LEAN_MEATS:
      return $t({ id: 'EatingCommitmentHealthyFood_leanMeats', defaultMessage: 'Lean meats' });
    case EatingCommitmentHealthyFoodEnum.GRAINS:
      return $t({ id: 'EatingCommitmentHealthyFood_grains', defaultMessage: 'Grains' });
    case EatingCommitmentHealthyFoodEnum.HEALTHY_FATS:
      return $t({
        id: 'EatingCommitmentHealthyFood_healthyFats',
        defaultMessage: 'Healthy fats (nuts, avocados, fish)',
      });
    case EatingCommitmentHealthyFoodEnum.OTHER:
    default:
      return $t({ id: 'EatingCommitmentHealthyFood_other', defaultMessage: 'Other' });
  }
}

export function getEatingCommitmentUnhealthyFoodLabel({
  $t,
  eatingCommitmentUnhealthyFood,
}: {
  $t: IntlShape['$t'];
  eatingCommitmentUnhealthyFood: EatingCommitmentUnhealthyFoodEnum;
}) {
  switch (eatingCommitmentUnhealthyFood) {
    case EatingCommitmentUnhealthyFoodEnum.SODA:
      return $t({ id: 'EatingCommitmentUnhealthyFood_soda', defaultMessage: 'Soda' });
    case EatingCommitmentUnhealthyFoodEnum.TAKEOUT:
      return $t({ id: 'EatingCommitmentUnhealthyFood_takeout', defaultMessage: 'Take out' });
    case EatingCommitmentUnhealthyFoodEnum.DESSERTS:
      return $t({ id: 'EatingCommitmentUnhealthyFood_desserts', defaultMessage: 'Desserts' });
    case EatingCommitmentUnhealthyFoodEnum.JUNK_FOOD:
      return $t({ id: 'EatingCommitmentUnhealthyFood_junkFood', defaultMessage: 'Junk food' });
    case EatingCommitmentUnhealthyFoodEnum.CANDY:
      return $t({ id: 'EatingCommitmentUnhealthyFood_candy', defaultMessage: 'Candy' });
    case EatingCommitmentUnhealthyFoodEnum.OTHER:
    default:
      return $t({ id: 'EatingCommitmentUnhealthyFood_other', defaultMessage: 'Other' });
  }
}
