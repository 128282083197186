import { Heading } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useTheme } from '@oui/app-core/src/styles';

export function Card(props: { title: string; children?: React.ReactNode }) {
  const { theme } = useTheme();
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: '#fff',
        margin: 19,
        padding: 20,
        borderRadius: 20,
        borderColor: theme.color.gray700,
        borderWidth: 1,
      }}
    >
      <Heading level={3} text={props.title} />
      <View
        style={{
          marginTop: 15,
        }}
      >
        {props.children}
      </View>
    </View>
  );
}
