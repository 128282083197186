import { useNavigation } from '@react-navigation/native';

import { ActivityDiaryPracticeItem } from '@oui/activity-diary';
import { PracticeItem } from '@oui/app-core/src/components/PracticeItem';
import { Heading, Label } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { useTheme } from '@oui/app-core/src/styles';
import { HopeKitPracticeItem } from '@oui/hope-kit';
import { FragmentOf, graphql, readFragment, ResultOf } from '@oui/lib/src/graphql/tada';
import { RelaxPracticeItem } from '@oui/relax-diary';
import { SleepDiaryPracticeItem, SleepDiaryPracticeItemFragment } from '@oui/sleep-diary';

import { TabScreenProps } from '@src/types/navigation';

export const WeeklyPracticeFragment = graphql(
  `
    fragment WeeklyPractice on ActionTodos {
      todo {
        __typename
        pendingActionID
        ... on PendingThoughtDiaryEntryTestAction {
          thoughtDiaryEntryPractice {
            practiceID
            practiceValues {
              date
            }
          }
        }
        ... on PendingThoughtDiaryEntrySwitchAction {
          thoughtDiaryEntryPractice {
            practiceID
            practiceValues {
              date
            }
          }
        }
        ... on PendingActivityRateAction {
          activityPractice {
            practiceID
            activity {
              title
            }
          }
        }
        ...SleepDiaryPracticeItem
      }
      completed {
        __typename
        actionID
        ... on ThoughtDiaryEntryTestAction {
          thoughtDiaryEntryPractice {
            practiceID
            practiceValues {
              date
            }
          }
        }
        ... on ThoughtDiaryEntrySwitchAction {
          thoughtDiaryEntryPractice {
            practiceID
            practiceValues {
              date
            }
          }
        }
        ... on ActivityRateAction {
          activityPractice {
            practiceID
            activity {
              title
            }
          }
        }
      }
    }
  `,
  [SleepDiaryPracticeItemFragment],
);

function ActionTodoItem(props: {
  item:
    | ResultOf<typeof WeeklyPracticeFragment>['todo'][number]
    | ResultOf<typeof WeeklyPracticeFragment>['completed'][number];
  complete?: boolean;
}) {
  const complete = props.complete || false;
  const { navigate } = useNavigation<TabScreenProps<'Home'>['navigation']>();
  const item = props.item;
  const { $t } = useI18n();
  const { theme } = useTheme();

  switch (item.__typename) {
    case 'PendingMyPlanReviewAction':
    case 'MyPlanReviewAction':
      return (
        <PracticeItem
          complete={complete}
          icon="my-plan"
          text={$t({ id: 'ActionTodoItem_myPlanReview', defaultMessage: 'Review using MyPlan' })}
          color={theme.color.accentThree100}
          onPress={() => navigate('BingeEatingMyPlan')}
        />
      );
    case 'PendingSleepDiaryEntryNightAction':
    case 'PendingSleepDiaryEntryMorningAction':
      return <SleepDiaryPracticeItem complete={complete} type={item.__typename} data={item} />;
    case 'SleepDiaryEntryNightAction':
    case 'SleepDiaryEntryMorningAction':
      return <SleepDiaryPracticeItem complete={complete} type={item.__typename} data={null} />;
    case 'PendingRelaxAction':
    case 'RelaxAction':
      return <RelaxPracticeItem complete={complete} />;
    case 'PendingHopeKitAddAction':
    case 'HopeKitAddAction':
    case 'PendingHopeKitReviewAction':
    case 'HopeKitReviewAction':
      return <HopeKitPracticeItem type={item.__typename} complete={complete} />;
    case 'PendingActivityRateAction':
    case 'ActivityRateAction':
      return (
        <ActivityDiaryPracticeItem
          complete={complete}
          type={item.__typename}
          practice={item.activityPractice}
        />
      );
    case 'PendingActivityAddAction':
    case 'ActivityAddAction':
      return (
        <ActivityDiaryPracticeItem complete={complete} type={item.__typename} practice={null} />
      );
    case 'PendingBreathHoldingAction': // TODO
    case 'BreathHoldingAction': // TODO
    case 'EatingLogAddAction':
    case 'PendingHyperventilationAction': // TODO
    case 'HyperventilationAction': // TODO
    case 'PendingEatingLogAddAction':
    case 'StaticAction':
    case 'PendingThoughtDiaryEntrySwitchAction':
    case 'ThoughtDiaryEntrySwitchAction':
    case 'PendingThoughtDiaryEntrySpotAction':
    case 'ThoughtDiaryEntrySpotAction':
    case 'PendingThoughtDiaryEntryTestAction':
    case 'ThoughtDiaryEntryTestAction':
    case 'PendingCopingCardReviewAction':
    case 'CopingCardReviewAction':
    case 'PendingCopingCardAddAction':
    case 'CopingCardAddAction':
    case 'PendingWordPairingAction': // TODO
    case 'WordPairingAction': // TODO
      return null;
  }
}

export const WeeklyPractice = ({
  practice,
}: {
  practice: FragmentOf<typeof WeeklyPracticeFragment>;
}) => {
  const { theme } = useTheme();
  const practiceData = readFragment(WeeklyPracticeFragment, practice);
  const hasRecords = !!practiceData.todo.length || !!practiceData.completed.length;

  return hasRecords ? (
    <View
      style={{
        padding: 20,
        paddingTop: 15,
        borderRadius: 20,
        backgroundColor: 'white',
      }}
    >
      <Heading
        level={3}
        text="Weekly practice"
        style={{
          marginBottom: 25,
        }}
      />
      <View
        style={{
          gap: 50,
        }}
      >
        {practiceData.todo.length ? (
          <View
            style={{
              gap: 25,
            }}
          >
            <Label small text="Up next" color={theme.color.gray300} />
            <View
              testID="WeeklyPractice_todo"
              style={{
                gap: 15,
              }}
            >
              {practiceData.todo.map((action, index) => (
                <View
                  key={action.pendingActionID}
                  style={{
                    paddingBottom: index === practiceData.todo.length - 1 ? 0 : 15,
                    borderBottomWidth: index === practiceData.todo.length - 1 ? 0 : 1,
                    borderColor: '#DEE0E5',
                  }}
                >
                  <ActionTodoItem item={action} />
                </View>
              ))}
            </View>
          </View>
        ) : null}
        {practiceData.completed.length ? (
          <View
            testID="WeeklyPractice_completed"
            style={{
              gap: 25,
            }}
          >
            <Label small text="Done" color={theme.color.gray300} />
            <View>
              {practiceData.completed.map((action, index) => (
                <View
                  key={action.__typename}
                  style={{
                    paddingTop: index === 0 ? 0 : 15,
                    paddingBottom: index === practiceData.todo.length - 1 ? 0 : 15,
                    borderBottomWidth: index === practiceData.todo.length - 1 ? 0 : 1,
                    borderColor: '#DEE0E5',
                  }}
                >
                  <ActionTodoItem item={action} complete />
                </View>
              ))}
            </View>
          </View>
        ) : null}
      </View>
    </View>
  ) : null;
};
