import { PracticeTile } from '@oui/app-core/src/components/PracticeTile';
import { useI18n } from '@oui/app-core/src/lib/i18n';

import { useEatingLogContext } from '../EatingLogContext';

type EatingLogPracticeTileProps = {
  lockedUntil?: number;
  practiceCount?: number;
  onPress: () => unknown;
};

export const EatingLogPracticeTile: React.FC<EatingLogPracticeTileProps> = ({
  onPress,
  lockedUntil,
  practiceCount,
}) => {
  const { $t } = useI18n();
  const eatingLogTheme = useEatingLogContext();

  return (
    <PracticeTile
      testID="PracticeTile_eatingLog"
      title={$t({ id: 'PracticeTile_eatingLog_title', defaultMessage: 'Eating log' })}
      description={$t({
        id: 'PracticeTile_eatingLog_description',
        defaultMessage: 'Record what you eat daily',
      })}
      color={eatingLogTheme.primaryColor}
      icon="eating-log"
      onPress={onPress}
      lockedUntil={lockedUntil}
      practiceCount={practiceCount}
    />
  );
};
