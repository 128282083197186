import { useMemo } from 'react';

import { useCompositionWithTitle } from '@oui/app-core/src/hooks/useComposition';
import { BingeEatingMyPlanCompositionSectionHash } from '@oui/lib/src/bingeEatingmyStoryMyPlanComposition';
import { CompositionTemplate } from '@oui/lib/src/types/graphql.generated';

function getSectionHashFromComposition(
  composition: NonNullable<ReturnType<typeof useCompositionWithTitle>['data']>,
): BingeEatingMyPlanCompositionSectionHash {
  return (
    composition.sections as unknown as ReadonlyArray<BingeEatingMyPlanCompositionSectionHash>
  ).reduce<BingeEatingMyPlanCompositionSectionHash>((carry, section) => {
    if (section) {
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      carry[section.title as any as keyof BingeEatingMyPlanCompositionSectionHash] = section as any;
    }
    return carry;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as any);
}

export function useBingeEatingMyPlanComposition({
  createIfUndefined,
}: {
  createIfUndefined?: boolean;
}) {
  const { data, ...rest } = useCompositionWithTitle({
    title: 'BINGE_EATING_MY_PLAN',
    template: CompositionTemplate.BINGE_EATING_MY_PLAN,
    createIfUndefined,
  });
  const formattedData = useMemo(() => {
    return data
      ? {
          ...data,
          sections: getSectionHashFromComposition(data),
        }
      : undefined;
  }, [data]);

  return { ...rest, data: formattedData };
}
